import clsx from 'clsx';

import ShiftEntry from '../shiftEntry/ShiftEntry';
import './Day.css'; 

export default function Day ({ day, completed, stores, employees, schedule, onChange }) {
  const handleShiftChange = (storeId, employeeId) => {
    onChange(day - 1, storeId, employeeId);
  };

  return schedule && (
    <div className={clsx('day', completed && 'day--completed')}>
      <h4 className="day__date">{day}</h4>
      {stores.map(store => {
        return (
          <ShiftEntry 
            key={`${store.id}-${day}`}
            store={store}
            employees={employees}
            schedule={schedule}
            onChange={handleShiftChange}
          />
        )})
      }
    </div>
  );
};