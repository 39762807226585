import { useRef, useMemo  } from 'react';
import clsx from 'clsx';
import html2canvas from 'html2canvas';
import IconButton from '@mui/material/IconButton';
import TuneIcon from '@mui/icons-material/Tune';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { MONTHS, WEEK_DAYS } from '../../common/constants'
import Day from '../day/Day';
import './Calendar.css';

export default function Calendar ({ year, month, stores, employees, filter, schedule, onChange, onFilterClick }) {
  const captureRef = useRef(null);

  const filteredSheldue = useMemo(() => {
    return schedule.map((day) => {
      const filteredDay = {};
      Object.entries(day).forEach(([storeId, employeeId]) => {
        const checkedEmployeeId = !filter.employeeId ? employeeId : employeeId === filter.employeeId ? employeeId : null;
        const checkedStoreId = !filter.storeId ? storeId : storeId === filter.storeId  ? storeId : null;
        if (checkedEmployeeId && checkedStoreId) {
          filteredDay[checkedStoreId] = checkedEmployeeId;
        }
      });
      return filteredDay;
    });
  }, [filter, schedule]);

  const isFilterActive = useMemo(() => Object.values(filter).some(value => value), [filter]);

  const handleCaptureClick = async () => {
    if (captureRef.current) {
      const canvas = await html2canvas(captureRef.current);
      canvas.toBlob(async (blob) => {
        if (blob) {
          try {
            await navigator.clipboard.write([
              new ClipboardItem({ 'image/png': blob })
            ]);
            alert('Календарь скопирован в буфер обмена!');
          } catch (error) {
            console.error('Ошибка копирования:', error);
          }
        }
      });
    }
  };

  const getFirstDayOfMonth = (year, month) => {
  const dayIndex = new Date(year, month, 1).getDay();
    return (dayIndex + 6) % 7;
  };
  
  const renderCalendar = () => {
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const firstDay = getFirstDayOfMonth(year, month);

    let days = [];
    for (let i = 0; i < firstDay; i++) {
      days.push(<div key={`empty-${i}`} className="empty-day"></div>);
    }

    for (let day = 1; day <= daysInMonth; day++) {
      const daySchedule = schedule?.[day - 1];

      const visibleStores = isFilterActive ? stores.filter((store) => filteredSheldue?.[day - 1]?.[store.id]) : stores;

      const dayEmployees = visibleStores.map((store) => daySchedule?.[store.id]);

      const isDayCompleted = dayEmployees.length && dayEmployees.every(emploeeId => 
        emploeeId !== undefined && emploeeId !== null
      );

      days.push(
        <Day 
          key={day}
          day={day}
          completed={isDayCompleted}
          stores={visibleStores}
          employees={employees}
          schedule={daySchedule}
          onChange={onChange}
        />
      );
    }
    return days;
  };
  
  return (
    <div ref={captureRef} className="calendar">
      <div className="calendar__header">
        <h3 className="calendar__month">
          {`${MONTHS[month]} ${year}`}
        </h3>
        <div className="calendar__buttons">
          <IconButton onClick={handleCaptureClick} >
            <ContentCopyIcon className="calendar__btn" />
          </IconButton>
          <IconButton onClick={onFilterClick} >
            <TuneIcon className={clsx('calendar__btn', isFilterActive && 'calendar__btn--active')}/>
          </IconButton>
        </div>
      </div>
      <div className="calendar__weekdays">
        {WEEK_DAYS.map(day => (
          <div key={day} className="calendar__weekday">{day}</div>
        ))}
      </div>
      <div className="calendar__days">
        {renderCalendar()}
      </div>
    </div>
  );
};