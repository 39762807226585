import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import DoneSharpIcon from '@mui/icons-material/DoneSharp';

import { nameRules } from '../../common/validationRules';
import './AddButton.css';

export default function AddButton({ label, onSubmit }) {
  const [isEdit, setEdit] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handlePreSubmit = ({ name }) => {
    onSubmit(name);
    reset();
    setEdit(false);
  };

  const handleReset = () => {
    reset();
    setEdit(false);
  };

  const isDisabledSubmitBtn = Object.keys(errors).length > 0;

  return isEdit ? (
    <div className="add-button">
      <form id="add-name-form" onSubmit={handleSubmit(handlePreSubmit)} noValidate>
        <Controller
          name="name"
          control={control}
          rules={nameRules}
          render={({ field }) => (
            <TextField
              {...field}
              value={field.value || ''}
              fullWidth
              sx={{
                border: 'none',
              }}
              variant="standard"
              error={!!errors.name}
              helperText={errors.name?.message || ''}
            />
          )}
        />
      </form>
      <IconButton type="submit" form="add-name-form" disabled={isDisabledSubmitBtn}>
        <DoneSharpIcon color="success" />
      </IconButton>
      <IconButton onClick={handleReset}>
        <CloseSharpIcon color="action" />
      </IconButton>
    </div>
    ) : (
    <Button 
      variant="text" 
      color="inherit" 
      startIcon={<AddIcon />}
      onClick={() => setEdit(true)}
    >
      {label}
    </Button>
  );
}