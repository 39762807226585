import MUIModal from '@mui/material/Modal';

import './Modal.css';

export default function Modal({ open, onClose, children }) {
  return (
    <MUIModal open={open} onClose={onClose}>
      <div className="modal">{children}</div>
    </MUIModal>
  );
}