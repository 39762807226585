import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';

import Card from '@mui/material/Card';
// import Switch from '@mui/material/Switch';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';


import { nameRules } from '../../common/validationRules';
import Modal from '../modal/Modal';
import './Store.css';

export default function Store ({ store, onUpdate, onDelete  }) {
  const [open, setOpen] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ defaultValues: {
    name: store.name, 
  }});

  const handlePreSubmit = (data) => {
    onUpdate(store.id, data);
    reset();
    setOpen(false);
  };

  const handleReset = () => {
    reset();
    setOpen(false);
  };

  const handleDelete = () => {
    onDelete(store.id);
    setOpen(false);
  };

  // const handleToggleVisibility = () => {
  //   onUpdate(store.id, { isVisible: !store.isVisible });
  // };

  return (
    <>
      <Card className="store">
        <h4 className="store__name">{store.name}</h4>
{/*        <Switch
          size="small"
          checked={store.isVisible}
          onChange={handleToggleVisibility}
        />*/}
        <IconButton size="small" onClick={() => setOpen(true)}>
          <EditIcon fontSize="small" />
        </IconButton>
      </Card>

      <Modal open={open} onClose={() => setOpen(false)}>
        <div>
          <h3 className="store__form-title">
            Изменить магазин
          </h3>
          <form 
            id="store-form" 
            className="store__form"
            onSubmit={handleSubmit(handlePreSubmit)} 
            noValidate
          >
            <Controller
              name="name"
              control={control}
              rules={nameRules}
              render={({ field }) => (
                <TextField
                  {...field}
                  value={field.value}
                  fullWidth
                  error={!!errors.name}
                  helperText={errors.name?.message || ''}
                />
              )}
            />
            <div className="store__form-buttons">
              <Button type="submit" variant="contained" >
                Сохранить
              </Button>
              <Button variant="outlined" onClick={handleReset}>
                Отмена
              </Button>
            </div>
            <Button variant="outlined" color="error" onClick={handleDelete}>
              Удалить магазин
            </Button>
          </form>
        </div>
      </Modal>
    </>
  );
};