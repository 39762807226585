export function getNameInitials(name) {
  const nameWords = name.split(' ').slice(0, 2);
  return nameWords.map((word) => word[0].toUpperCase()).join('');
}

export function countShiftsByEmployee(schedule) {
  const shiftsCount = {};
  for (const day of schedule) {
    Object.values(day).forEach((employeeId) => {
      if (shiftsCount[employeeId]) {
        shiftsCount[employeeId] = shiftsCount[employeeId] + 1;
      } else {
        shiftsCount[employeeId] = 1;
      }
    });
  }
  return shiftsCount; 
}