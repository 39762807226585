import { useForm, Controller } from 'react-hook-form';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import './FilterMenu.css';

export default function FilterMenu({ filter, stores, employees, open, onSubmit, setOpen }) {
  const defaultValues = {
    storeId: filter.storeId || '', 
    employeeId: filter.employeeId || '', 
  }

  const {
    control,
    handleSubmit,
    reset,
  } = useForm({ defaultValues });

  const handlePreSubmit = (data) => {
    const checkedData = Object.fromEntries(Object.entries(data).filter(([key, value]) => value !== ''));
    onSubmit(checkedData);
    setOpen(false);
  };

  const handleReset = () => {
    onSubmit({});
    reset();
    setOpen(false);
  };

  return (
    <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
      <div className="filter-menu">
        <form 
          id="filter-form" 
          className="filter-menu__form"
          onSubmit={handleSubmit(handlePreSubmit)} 
          noValidate
        >
          <Controller
            name="storeId"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <InputLabel htmlFor="store">Магазин</InputLabel>
                <Select
                  {...field}
                  id="store"
                  label="Магазин"
                  fullWidth
                >
                {stores.map((store) => (
                  <MenuItem key={store.id} value={store.id}>
                    {store.name}
                  </MenuItem>
                ))}
                  <MenuItem key="empty" value="">
                    Не выбран
                  </MenuItem>
                </Select>
              </FormControl>
            )}
          />
          <Controller
            name="employeeId"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <InputLabel htmlFor="employee">Сотрудник</InputLabel>
                <Select
                  {...field}
                  id="employee"
                  label="Сотрудник"
                  fullWidth
                >
                {employees.map((employee) => (
                  <MenuItem key={employee.id} value={employee.id}>
                    {employee.name}
                  </MenuItem>
                ))}
                  <MenuItem key="empty" value="">
                    Не выбран
                  </MenuItem>
                </Select>
              </FormControl>
            )}
          />
          <div className="filter-menu__buttons">
            <Button type="submit" variant="contained" >
              Применить
            </Button>
            <Button variant="outlined" onClick={handleReset}>
              Сброс
            </Button>
          </div>
        </form>
      </div>
    </Drawer>
  );
}