export const MONTHS = [
  'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'
];

export const WEEK_DAYS = ['Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота', 'Воскресенье'];

export const LOCAL_KEYS = {
  MONTH: 'shift-planner_month',
  STORES: 'shift-planner_stores',
  EMPLOYEES: 'shift-planner_employees',
  SCHEDULE: 'shift-planner',
}