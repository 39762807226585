import React, { useState, useMemo } from 'react';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import PersonOffIcon from '@mui/icons-material/PersonOff';

import { getNameInitials } from '../../services/employeeService';
import './ShiftEntry.css';

export default function ShiftEntry({store, employees, schedule, onChange }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e) => {
    e.stopPropagation()
    setAnchorEl(null);
  };

  const handleShiftChange = (e, employeeId) => {
    e.stopPropagation()
    setAnchorEl(null);
    onChange(store.id, employeeId);
  };

  const employeeId = schedule[store.id];
  const employee = employeeId && employees.find((employee) => employee.id === employeeId);
  const employeeName = employeeId ? employee?.name || '?' : '';
  const freeEmployees = useMemo(
    () => employees.filter((employee) => !Object.values(schedule).includes(employee.id)), 
    [employees, schedule]
  );

  const isShiftVisible = !employeeId ? true : !employee ? true : employee.isVisible ? true : false;

  return isShiftVisible ? (
  <div className="shift-entry" onClick={handleOpen}
  >
    {employeeName ? (
      <Avatar className="shift-entry__avatar"  sx={{ bgcolor: employee?.color || '' }}>
        {getNameInitials(employeeName)}
      </Avatar>
      ) : (
      <div className="shift-entry__avatar">...</div>
      )}
    <p className="shift-entry__store">{store.name}</p>
    <Menu
      anchorEl={anchorEl}
      open={open}
      autoFocus={false}
      onClose={handleClose}
      slotProps={{
        paper: {
          style: {
            maxHeight: 200,
            width: '20ch',
          },
        },
      }}
    >
      {freeEmployees.length ? freeEmployees.map((employee) => (
        <MenuItem key={employee.id} onClick={(e) => handleShiftChange(e, employee.id)}>
          <ListItemIcon>
            <PersonOutlineIcon fontSize="small" />
          </ListItemIcon>
          {employee.name}
        </MenuItem>
      )) : !employeeId ? (
        <MenuItem key="empty" onClick={handleClose}>
          <ListItemIcon>
            <PersonOffIcon fontSize="small" />
          </ListItemIcon>
          Нет свободных
        </MenuItem>
      ) : null}
      {employeeId ? (
        <MenuItem key="reset" onClick={(e) => handleShiftChange(e, null)}>
          <ListItemIcon>
            <PersonRemoveIcon fontSize="small" />
          </ListItemIcon>
          Очистить
        </MenuItem>
        ) : null}
    </Menu>
  </div>
  ) : null
};