import { LOCAL_KEYS } from '../common/constants';

export function getLocalValue(key) {
  const value = localStorage.getItem(key);
  return value && JSON.parse(value);
}

export function setLocalValue(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
}

export function removeLocalValue(key) {
  localStorage.removeItem(key);
}

export function getScheduleKey(selectedYear, selectedMonth) {
  return `${LOCAL_KEYS.SCHEDULE}_${selectedYear}-${selectedMonth + 1}`;
}