import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import Badge from '@mui/material/Badge';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
// import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import EditIcon from '@mui/icons-material/Edit';
import CircleIcon from '@mui/icons-material/Circle';

import { nameRules } from '../../common/validationRules';
import { COLORS } from '../../common/colors';
import { getNameInitials } from '../../services/employeeService';
import Modal from '../modal/Modal';
import './Employee.css';

export default function Employee ({ employee, onUpdate, onDelete, shiftsCount = 0 }) {
  const [open, setOpen] = useState(false);

  const defaultValues = {
    name: employee.name, 
    color: employee.color, 
  }

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ defaultValues });

  const handlePreSubmit = (data) => {
    onUpdate(employee.id, data);
    reset();
    setOpen(false);
  };

  const handleReset = () => {
    reset();
    setOpen(false);
  };

  const handleDelete = () => {
    onDelete(employee.id);
    setOpen(false);
  };

  // const handleToggleVisibility = () => {
  //   onUpdate(employee.id, { isVisible: !employee.isVisible });
  // };

  return (
    <>
      <Badge 
        badgeContent={shiftsCount} 
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        sx={{
          "& .MuiBadge-badge": {
            color: "white",
            backgroundColor: "#8b8b8b"
          }
        }}
      >
        <Card className="employee">
          <Avatar className="employee__avatar" sx={{ bgcolor: employee.color }}>
            {getNameInitials(employee.name)}
          </Avatar>
          <h4 className="employee__name">{employee.name}</h4>
{/*          <Switch
            size="small"
            checked={employee.isVisible}
            onChange={handleToggleVisibility}
          />*/}
          <IconButton size="small" onClick={() => setOpen(true)}>
            <EditIcon fontSize="small" />
          </IconButton>
        </Card>
      </Badge>

      <Modal open={open} onClose={() => setOpen(false)}>
        <div>
          <h3 className="employee__form-title">
            Изменить сотрудника
          </h3>
          <form 
            id="employee-form" 
            className="employee__form"
            onSubmit={handleSubmit(handlePreSubmit)} 
            noValidate
          >
            <Controller
              name="name"
              control={control}
              rules={nameRules}
              render={({ field }) => (
                <TextField
                  {...field}
                  value={field.value}
                  fullWidth
                  error={!!errors.name}
                  helperText={errors.name?.message || ''}
                />
              )}
            />
            <Controller
              name="color"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel htmlFor="employee-color">Цвет</InputLabel>
                  <Select
                    {...field}
                    id="employee-color"
                    label="Цвет"
                    fullWidth
                  >
                  {COLORS.map((color) => (
                    <MenuItem key={color.value} value={color.value}>
                      <ListItemIcon>
                        <CircleIcon sx={{ color: color.value }} />
                      </ListItemIcon>
                      {color.label}
                    </MenuItem>
                  ))}
                    <MenuItem key="empty" value="">
                      <ListItemIcon>
                        <CircleIcon sx={{ color: '#9e9e9e' }} />
                      </ListItemIcon>
                      Без цвета
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
            <div className="employee__form-buttons">
              <Button type="submit" variant="contained" >
                Сохранить
              </Button>
              <Button variant="outlined" onClick={handleReset}>
                Отмена
              </Button>
            </div>
            <Button variant="outlined" color="error" onClick={handleDelete}>
              Удалить сотрудника
            </Button>
          </form>
        </div>
      </Modal>
    </>

  );
};