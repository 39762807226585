export const COLORS = [
	{ label: 'Emerald-200', value: '#a7f3d0' },
	{ label: 'Emerald-400', value: '#34d399' },
	{ label: 'Emerald-600', value: '#059669' },
	{ label: 'Teal-400', value: '#2dd4bf' },
	{ label: 'Teal-600', value: '#0d9488' },
	{ label: 'Cyan-400', value: '#22d3ee' },
	{ label: 'Cyan-600', value: '#0891b2' },
	{ label: 'Sky-400', value: '#38bdf8' },
	{ label: 'Sky-600', value: '#0284c7' },
	{ label: 'Blue-400', value: '#60a5fa' },
	{ label: 'Blue-600', value: '#2563eb' },
	{ label: 'Indigo-400', value: '#818cf8' },
	{ label: 'Indigo-600', value: '#4f46e5' },
	{ label: 'Violet-400', value: '#a78bfa' },
	{ label: 'Violet-600', value: '#7c3aed' },
];

