import React, { useState, useEffect, useMemo } from 'react';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { v4 as uuidv4 } from 'uuid';

import { MONTHS, LOCAL_KEYS } from '../../common/constants';
import { getLocalValue, setLocalValue, getScheduleKey } from '../../services/storageService';
import { countShiftsByEmployee } from '../../services/employeeService';
import Calendar from '../calendar/Calendar';
import FilterMenu  from '../filterMenu/FilterMenu';
import Store from '../store/Store';
import Employee from '../employee/Employee';
import AddButton from '../addButton/AddButton';
import './ShiftPlanner.css';


export default function ShiftPlanner() {
  const [selectedMonth, setSelectedMonth] = useState(() => {
    const savedMonth = getLocalValue(LOCAL_KEYS.MONTH);
    return savedMonth || new Date().getMonth()
  });
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [stores, setStores] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const [filter, setFilter] = useState({});
  const [openFilter, setFilterOpen] = useState(false);

  useEffect(() => {
    const localStores = getLocalValue(LOCAL_KEYS.STORES);
    if (localStores) {
      setStores(localStores);
    }
    const localEmployees = getLocalValue(LOCAL_KEYS.EMPLOYEES);
    if (localEmployees) {
      setEmployees(localEmployees);
    }
    const scheduleKey = getScheduleKey(selectedYear, selectedMonth);
    const localSchedule = getLocalValue(scheduleKey);
    if (localSchedule) {
      setSchedule(localSchedule);
    } else {
      const newSchedule = initializeSchedule(selectedYear, selectedMonth);
      setSchedule(newSchedule);
      setLocalValue(scheduleKey, newSchedule);
    }
  }, [selectedYear, selectedMonth]);

  useEffect(() => {
    setLocalValue(LOCAL_KEYS.MONTH, selectedMonth);
  }, [selectedMonth])

  useEffect(() => {
    if (schedule.length) {
      const scheduleKey = getScheduleKey(selectedYear, selectedMonth);
      setLocalValue(scheduleKey, schedule);
    }
  }, [schedule, selectedYear, selectedMonth])

  useEffect(() => {
    if (stores.length) {
      setLocalValue(LOCAL_KEYS.STORES, stores);
    }
  }, [stores])

  useEffect(() => {
    if (employees.length) {
      setLocalValue(LOCAL_KEYS.EMPLOYEES, employees);
    }
  }, [employees])  

  const shiftsCountByEmployee = useMemo(() => {
    return countShiftsByEmployee(schedule);
  }, [schedule]);

  const handleCloseFilter = () => {
    setFilterOpen(true);
  };

  const initializeSchedule = (selectedYear, selectedMonth) => {
    const daysInMonth = new Date(selectedYear, selectedMonth + 1, 0).getDate();
    return Array(daysInMonth).fill({});
  };

  const handleUpdateSchedule = (dayIndex, storeId, employeeId) => {
    setSchedule(prevSchedule => {
      const updatedDay = {
        ...prevSchedule[dayIndex],
        [storeId]: employeeId
      };
      if (employeeId === null) {
        delete updatedDay[storeId];
      }
      
      return [
        ...prevSchedule.slice(0, dayIndex),
        updatedDay,
        ...prevSchedule.slice(dayIndex + 1)
      ];
    });
  }

  const handleUpdateStore = (storeId, update) => {
    setStores((prevStores) =>
      prevStores.map((store) =>
        store.id === storeId ? { ...store, ...update } : store
      )
    );
  };

  const handleUpdateEmployee = (employeeId, update) => {
    setEmployees((prevEmployees) =>
      prevEmployees.map((employee) =>
        employee.id === employeeId ? { ...employee, ...update } : employee
      )
    );
  };  

  const handleAddStore = (newStoreName) => {
    if (newStoreName && !stores.find((store) => store.name === newStoreName)) {
      const newStore = {
        id: uuidv4(), 
        name: newStoreName, 
        isVisible: true,
        color: ''
      };
      const updatedStores = [...stores, newStore];
      setStores(updatedStores);
      setLocalValue(LOCAL_KEYS.STORES, updatedStores);
    } else {
      alert('Магазин уже существует');
    }
  };

  const handleAddEmployee = (newEmployeeName) => {
    if (newEmployeeName && !employees.find((employee) => employee.name === newEmployeeName)) {
      const newEmployee = { 
        id: uuidv4(), 
        name: newEmployeeName, 
        isVisible: true,
        color: ''
      };
      const updatedEmployees = [...employees, newEmployee];
      setEmployees(updatedEmployees);
      setLocalValue(LOCAL_KEYS.EMPLOYEES, updatedEmployees);
    } else {
      alert('Сотрудник уже существует');
    }
  };

  const handleDeleteStore = (storeId) => {
    setStores((prevStores) =>
      prevStores.filter((store) => store.id !== storeId)
    );
  };

  const handleDeleteEmployee = (employeeId) => {
    setEmployees((prevEmployees) =>
      prevEmployees.filter((employee) => employee.id !== employeeId)
    );
  }; 

  return (
    <div className="shift-planner">
      <div className="shift-planner__header">
        <h1 className="shift-planner__title">Планировщик смен</h1>
        <div className="controls">
          <FormControl variant="standard" className="controls__month-input">
            <Select
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(parseInt(e.target.value))}
            >
              {MONTHS.map((month, index) => (
                <MenuItem key={index} value={index}>{month}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            type="number"
            variant="standard"
            value={selectedYear}
            onChange={(e) => setSelectedYear(parseInt(e.target.value))}
            className="controls__year-input"
            inputProps={{ min: '2022', max: '2100' }}
          />
        </div>
      </div>

      <div className="control-panel">
        <div className="control-panel__header">
          <h4 className="control-panel__title">Магазины:</h4>
          <AddButton label="Добавить магазин" onSubmit={handleAddStore} />
        </div>
        <div className="control-panel__container">
          {stores.map((store) => (
            <Store 
              key={store.id} 
              store={store}
              onUpdate={handleUpdateStore}
              onDelete={handleDeleteStore}
            />
          ))}
        </div>
      </div>
      <div className="control-panel">
        <div className="control-panel__header">
          <h4 className="control-panel__title">Сотрудники:</h4>
          <AddButton label="Добавить сотрудника" onSubmit={handleAddEmployee}/>
        </div>
        <div className="control-panel__container">
          {employees.map((employee) => (
            <Employee 
              key={employee.id} 
              employee={employee} 
              onUpdate={handleUpdateEmployee}
              onDelete={handleDeleteEmployee}
              shiftsCount={shiftsCountByEmployee[employee.id]}
            />
          ))}
        </div>
      </div>

      <Calendar 
  	    year={selectedYear}
    		month={selectedMonth}
    		stores={stores}
        employees={employees}
        filter={filter}
    		schedule={schedule}
        onChange={handleUpdateSchedule}
        onFilterClick={handleCloseFilter}
  	  />
      <FilterMenu 
        filter={filter}
        stores={stores}
        employees={employees}
        open={openFilter} 
        setOpen={setFilterOpen}
        onSubmit={setFilter}
      />
    </div>
  );
};
